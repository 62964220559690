import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';

import Layout from '@layout/Layout';
import PageContainer from '@layout/PageContainer';
import Seo from '@components/Seo/Seo';
import Spinner from '@components/Spinner/Spinner';
import Newsletter from '@components/Newsletter/Newsletter';
import Modal from 'react-modal';

import Colors from '@utils/colors';
import Utils from '@utils/';
import Breakpoints from '@utils/breakpoints';

import Img from '@static/img/eventi-speciali.jpg';

import PageHeader from '@components/PageHeader/PageHeader';

Modal.setAppElement('#___gatsby');

const Styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  modalStyles: {
    top: '20px',
    left: '20px',
    right: '20px',
    bottom: '20px',
    zIndex: 500,
    background: Colors.alternateBg,
    border: 'none',
    padding: '0',
    position: 'absolute',
    overflow: 'auto',
    [`@media (max-width: ${Breakpoints.s})`]: {
      left: '0',
      right: '0',
      top: '0',
      bottom: '0',
    },
  },
  close: {
    position: 'absolute',
    top: '15px',
    right: '20px',
    zIndex: '150',
    color: Colors.white + ' !important',
    textDecoration: 'none !important',
    backgroundColor: Colors.accent,
    borderRadius: '4px',
    padding: '10px 15px',
    fontSize: '14px',
    textTransform: 'uppercase',
    display: 'inline-block',
    fontWeight: 'bold',
    transition: Utils.transition('background', '.2s'),
    letterSpacing: 1,
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      backgroundColor: Colors.accentDark,
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      top: '10px',
      fontSize: '14px',
      padding: '5px 10px',
    },
  },
  iframe: {
    zIndex: 99999,
  },
};

const useStyles = createUseStyles(Styles);

const LezioneProva = ({ location, ...props }) => {
  const classes = useStyles(props);

  const [open, handleOpen] = useState(false);

  const title = 'Gli eventi speciali della LiveFlow Community';
  const subtitle =
    'Qui puoi prenotarti per i nostri eventi di movimento, spesso gratuiti e sempre adatti e tutti, con lo scopo di connetterci con il nostro corpo e ritrovare il piacere di muoverci insieme.';
  const more =
    'Per tenerti aggiornat*, iscriviti alla nostra newsletter in fondo alla pagina.<br/><br/>Inoltre, ti puoi unire a noi nel nostro gruppo Facebook <strong><a href="https://www.facebook.com/liveflow.it" target="_blank">La LiveFlow Community</a></strong>, se senti il bisogno di cambiare la tua relazione con il tuo corpo e con l\'allenamento.<br/><br/>Insieme, cerchiamo di creare qui una comunità di movimento, aperta a tante interpretazioni diverse di che cosa voglia dire “bello”: <strong>a prescindere da misure, forme e abilità fisica, età, genere ed etnia</strong>.';

  // useEffect(() => {
  //   if (window.Calendly) {
  //     setTimeout(() => {
  //       window.Calendly.initInlineWidget({
  //         url: `https://calendly.com/liveflow/eventi-speciali?text_color=29252d&primary_color=ff976c`,
  //         parentElement: document.getElementById('calendly-inline-widget'),
  //         prefill: {},
  //         utm: {},
  //       });
  //     }, 200);
  //   }
  // }, []);

  return (
    <Layout>
      <Seo title={title} description={subtitle} url={location.pathname} />
      <PageContainer>
        <PageHeader title={title} subtitle={subtitle} nomargin center />
        <a className="lezione-di-prova-img-link" href="#video">
          <img
            className="lezione-di-prova-img"
            src={Img}
            alt="Lezione gratuita"
          />
        </a>
        <br />
        <PageHeader title={''} subtitle={more} center />

        {/* <div className="relative">
          <div className="absolute_spinner">
            <Spinner loading={true} />
          </div>
          <div
            id="calendly-inline-widget"
            className="calendly-inline-widget absolute_calendly"
            data-url="https://calendly.com/liveflow/eventi-speciali?text_color=29252d&primary_color=ff976c"
            style={{ minWidth: '320px', height: '750px' }}
          ></div>
        </div> */}

        <Newsletter />
      </PageContainer>

      <Modal
        isOpen={open}
        className={classes.content}
        onRequestClose={() => {
          handleOpen(false);
        }}
        shouldCloseOnOverlayClick={true}
        onClick={() => {
          handleOpen(false);
        }}
      >
        <a
          href="#close"
          className={classes.close}
          onClick={e => {
            e.preventDefault();
            handleOpen(false);
          }}
        >
          CHIUDI
        </a>
        <div className={classes.container}>
          <div className="absolute_spinner absolute_spinner--modal">
            <Spinner loading={true} />
          </div>
          <iframe
            className={classes.iframe}
            title="liveflow_video"
            src="https://player.vimeo.com/video/472142837"
            width="640"
            height="480"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </Modal>
    </Layout>
  );
};

export default LezioneProva;
